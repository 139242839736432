import React from 'react';
import ImagesProvider from '@/contexts/images';
import { Seo } from '@/components';
import { Root } from '@/layouts';
import Hero from '@/layouts/Funcionalidades/Hero';
import { ConhecaMais, ConhecaPlanos, Detail } from '@/layouts/Funcionalidades';
import FUNCIONALIDADES from '@/constants/funcionalidades';
import { GestaoFinancasDetails } from '@/constants/pageDetails';

import gestao from '@/assets/images/menu-financeiro-simples-dental.svg';
import { buttonEvents } from '@/constants/analytics';

const schema = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
      item: 'https://www.simplesdental.com/',
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: 'Gestão Odontológica',
    },
  ],
};

const GestaoFinanceiro = () => {
  const name = FUNCIONALIDADES.gestaoFinancas.name;

  return (
    <ImagesProvider>
      <Seo
        title="Organize sua clínica e simplifique sua gestão"
        description="Tenha o controle total da gestão da sua clínica no Simples Dental. Emita boletos, tenha integração com a máquina de cartão e muito mais."
        schema={schema}
      />
      <Root
        variant="light"
        hero={
          <Hero
            title={name}
            subtitle="Ganhe mais tempo"
            desc="Tenha o domínio total da sua clínica"
            img={gestao}
            alt={name}
            imageTitle="Menu Financeiro do Simples Dental"
            gaMetadata={{ buttonEvent: buttonEvents.gestaoFinanceiro }}
            width={690}
            height={405}
          />
        }
      >
        {GestaoFinancasDetails.map((i, index) => (
          <Detail item={i} index={index} key={index} />
        ))}

        <ConhecaPlanos gaMetadata={{ buttonEvent: buttonEvents.gestaoFinanceiro }} />

        <ConhecaMais
          page={name}
          gaMetadata={{ saibaMaisButtonEvent: buttonEvents.gestaoFinanceiro }}
        />
      </Root>
    </ImagesProvider>
  );
};

export default GestaoFinanceiro;
